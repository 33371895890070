import React, { useState } from 'react'
import manucureData from '../../assets/data/manucure.json'
import pedicureData from '../../assets/data/pedicure.json'
import Button from '../Button'
import PrestaModal from './Modal'
import './style.scss'

function Prestation() {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <div className="container prestation-container">
      {/* Section Manucure */}
      <div className="category">
        <h2 className="category-title">Manucure</h2>

        <div className="category-card">
          {manucureData.map((prestation) => (
            <div key={prestation.id} className="card">
              {prestation.image.src ? (
                <img
                  src={require(`../../${prestation.image.src}`)}
                  alt={prestation.image.alt}
                  className="card-image"
                  loading="lazy"
                />
              ) : (
                <div className="placeholder-image">Image non disponible</div>
              )}

              <div className="card-content">
                <h2>{prestation.nom}</h2>
                <div className="content-row">
                  <div className="details">
                    <p>{prestation.prix}</p>
                    <p>{prestation.duree}</p>
                  </div>
                  <a
                    href={prestation.externalLink}
                    className="card-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="reservation-button">
                      <span>Réserver</span>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          ))}
          <div className="button-supplement-container">
            <Button onClick={openModal} className="supplement-button">
              <span>Voir Suppléments</span>
            </Button>
          </div>
        </div>
      </div>

      {/* Section Pedicure */}
      <div className="category pedicure-category">
        <h2 className="category-title">Pédicure</h2>

        <div className="category-card">
          {pedicureData.map((prestation) => (
            <div key={prestation.id} className="card">
              {prestation.image.src ? (
                <img
                  src={require(`../../${prestation.image.src}`)}
                  alt={prestation.image.alt}
                  className="card-image"
                  loading="lazy"
                />
              ) : (
                <div className="placeholder-image">Image non disponible</div>
              )}

              <div className="card-content">
                <h2>{prestation.nom}</h2>
                <div className="content-row">
                  <div className="details">
                    <p>{prestation.prix}</p>
                    <p>{prestation.duree}</p>
                  </div>
                  <a
                    href={prestation.externalLink}
                    className="card-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="reservation-button">
                      <span>Réserver</span>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showModal && <PrestaModal show={showModal} onClose={closeModal} />}
    </div>
  )
}

export default Prestation
