import { useState } from 'react'
import Logo from '../../assets/images/logo.png'
import { Link, NavLink } from 'react-router-dom'
import './style.scss'

function Sidebar() {
  const [showNav, setShowNav] = useState(false)

  return (
    <div className="nav-bar">
      <Link className="logo" to="/" onClick={() => setShowNav(false)}>
        <img src={Logo} alt="Logo" />
      </Link>

      <nav className={showNav ? 'mobile-show' : ''}>
        <NavLink
          exact="true"
          activeclassname="active"
          to="/"
          onClick={() => setShowNav(false)}
          data-text="ACCUEIL"
        >
          <i className="far fa-house"></i>
        </NavLink>

        <NavLink
          activeclassname="active"
          className="presentation-link"
          to="/information"
          onClick={() => setShowNav(false)}
          data-text="INFOS"
        >
          <i className="fa-regular fa-circle-info"></i>
        </NavLink>

        <NavLink
          activeclassname="active"
          className="prestation-link"
          to="/prestation"
          onClick={() => setShowNav(false)}
          data-text="PRESTATIONS"
        >
          <i className="fa-light fa-calendar-check"></i>
        </NavLink>

        <NavLink
          activeclassname="active"
          className="contact-link"
          to="/contact"
          onClick={() => setShowNav(false)}
          data-text="CONTACT"
        >
          <i className="far fa-envelope"></i>
        </NavLink>

        <NavLink
          activeclassname="active"
          className="reviews-link"
          to="/reviews"
          onClick={() => setShowNav(false)}
          data-text="AVIS"
        >
          <i className="fa-sharp fa-solid fa-star"></i>
        </NavLink>
      </nav>

      <ul>
        <li>
          <a
            href="https://www.instagram.com/sarah.manucure/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-instagram anchor-icon"></i>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Sidebar
